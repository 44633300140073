import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const JoinPage = () => {
  return (
    <Layout>
      <SEO
        title={"Join"}
        description={"The meeting place for people, products, and great design"}
      />
      <section className="slide whiteSlide kenBurns">
        <div className="content">
          <div className="container">
            <div className="wrap">
              <div className="fix-8-12">
                <h2 className="ae-1 normal margin-bottom-2">
                  Join our fast-growing community of product enthusiasts learning
                  to build solutions the right way.
                </h2>
                {/* <form class="ae-1 form-group width-lg" netlify action="join_unflux.html">*/}
                {/*   <label for="uemail">Email</label>*/}
                {/*   <input class="form-control rounded" type="email" id="uemail" name="email"*/}
                {/*      placeholder="Enter your email address...">*/}
                {/*   <input type="submit" value="Join the Unflux" class="button rounded margin-top-2 ae-5 fromCenter">*/}
                {/*</form> */}
                <form
                  className="ae-1 form-group width-lg"
                  action="https://submit-form.com/boc943XR"
                  target="_self"
                >
                  <input
                    type="hidden"
                    name="_redirect"
                    value="https://theunflux.com/thanks"
                  />
                  <input type="hidden" name="_append" value="false" />
                  <label htmlFor="uemail">Email</label>
                  <input
                    className="form-control rounded"
                    type="email"
                    id="uemail"
                    name="email"
                    placeholder="Enter your email address..."
                    required
                  />
                  <input
                    type="submit"
                    value="Become a member"
                    className="button rounded margin-top-2 ae-5 fromCenter"
                  />
                </form>
                <div className="tiny-list">
                  <ul>
                    <li className="ae-7">
                      <span>
                        <img src="/assets/img/flat-star.png" /> Complete content
                        library
                      </span>
                    </li>
                    <li className="ae-8">
                      <span>
                        <img src="/assets/img/flat-star.png" /> Curated learning
                        paths
                      </span>
                    </li>
                    <li className="ae-9">
                      <span>
                        <img src="/assets/img/flat-star.png" /> Private coaching and one-on-one's
                      </span>
                    </li>
                    <li className="ae-10">
                      <span>
                        <img src="/assets/img/flat-star.png" /> Discounted access to workshops and events
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="background"
          style={{ backgroundImage: "url(/assets/img/background/img-12.png)" }}
        ></div>
      </section>
    </Layout>
  );
};

export default JoinPage;
